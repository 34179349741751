<template>
  <div class="logout">
    <div class="logout-container">
      <h3>{{ $t('LOGOUT_SUCCESSFULL') }}</h3>
      <p>
        <router-link :to="{ path: '/login' }" class="el-button el-button--primary">{{ $t('LOGIN') }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import config from '@/config/index';

export default {
  name: 'Logout',

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const isSuccess = urlParams.get('success');

    if (!isSuccess && isSuccess !== 'true') {
      // Send event to analytics
      if (window.ga) {
        ga('send', 'event', 'Authentication', 'logout', this.$session.get('userlogin'));
      }

      // Redirect to signout page of LoginService
      if (config.login.host && config.login.signOutApp) {
        window.location.href = `${config.login.host}${config.login.signOutApp}`;
      }
    }

    this.$store.commit('auth/CLEAR_AUTH');
  }
};
</script>

<style lang="scss">
.logout {
  background-image: url('./../assets/img/childs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 50%;
  min-height: 90vh;
  height: auto;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $screen-sm) {
    background-position-x: unset;
  }

  @media screen and (min-width: $screen-lg) {
    background-position-y: center;
    min-height: 70vh;
  }

  &-container {
    background-color: rgba(255, 255, 255, 0.95);
    border: 3px solid $c-brand;
    box-shadow: 0 0 5px 0 transparentize($c-black, 0.8);
    padding: 2rem 2rem 1rem;
    width: 90%;

    @media screen and (min-width: $screen-xs) {
      padding: 4rem 5rem;
      width: 70%;
    }

    @media screen and (min-width: $screen-md) {
      width: 50%;
    }

    @media screen and (min-width: $screen-lg) {
      width: 40%;
    }

    @media screen and (min-width: 1450px) {
      width: 35%;
    }

    @media screen and (min-width: $screen-xl) {
      width: 30%;
    }

    &--wide {
      width: 100%;

      @media screen and (min-width: $screen-md) {
        width: 70%;
      }
    }
  }
}
</style>
